// uncomment the line below if you want to activate why-did-you-render
// import './wdyr';
import 'core-js';
import 'regenerator-runtime/runtime';
// @ts-expect-error [TS migration] Was not detected by flow
import amplitude from 'amplitude-js';

import * as React from 'react';

import store from 'store/store';
import { getClientBind } from 'store/APIClient';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';
import { LicenseManager } from 'ag-grid-enterprise';
import 'styles/main.scss';
import './i18n';
import { router } from 'store/router';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import {
    AG_GRID_KEY,
    AMPLITUDE_KEY,
    FLAVOUR,
    IS_DEV,
    IS_PRESENTATION,
    IS_PROD,
    IS_STAGING,
    IS_TEST,
    SENTRY_DSN,
    VERSION,
} from 'env';

LicenseManager.setLicenseKey(AG_GRID_KEY);

// we remove the window onload function to avoid infinite reload
// see index.html for details
window.onload = () => {};

// www.manty.eu redirection. Simpler than in ovh and traefik...
if (window.location.host === 'www.manty.eu') {
    window.location.host = 'manty.eu';
}

// Setup Sentry for exception monitoring, Hotjar etc
if (IS_STAGING || IS_PROD || IS_PRESENTATION) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: IS_PROD ? 'production' : 'staging',
        release: VERSION,
    });
    Sentry.setTag('flavour', FLAVOUR);

    const isE2eTest = window.location.host.includes('e2e-test-app');

    amplitude.getInstance().init(AMPLITUDE_KEY);

    // Do not load datadog-RUM in e2e test
    if (!isE2eTest) {
        const bind = getClientBind();

        datadogRum.init({
            applicationId: 'd568b27d-573b-4af3-a107-57f7b55a4446',
            clientToken: 'pubd605e6807fc7051b6ac75c77bb672d06',
            site: 'datadoghq.com',
            service: 'vision',
            env: IS_PROD ? 'production' : 'staging',
            sampleRate: 100,
            trackInteractions: true,
            trackViewsManually: true,
            allowedTracingOrigins: [/https:\/\/.*\.manty\.eu/],
        });

        datadogRum.setGlobalContextProperty('bind', { bind });
        datadogRum.setGlobalContextProperty('flavour', FLAVOUR);
    }
}

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');
if (!MOUNT_NODE) {
    console.error('Root element not found');
} else {
    const root = createRoot(MOUNT_NODE);

    let render = () => {
        // Ensure StrictMode is only applied in development environment
        const app = IS_DEV ? (
            <StrictMode>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </StrictMode>
        ) : (
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        );
        root.render(app);
    };

    // This code is excluded from production bundle
    if (IS_DEV) {
        // @ts-expect-error [TS migration] (previously $FlowFixMe)
        if (module.hot) {
            // Development render functions
            const renderApp = render;

            // Wrap render in try/catch
            render = () => {
                try {
                    renderApp();
                } catch (error) {
                    console.error(error);
                    root.render(<p>An error happened, see console</p>);
                }
            };

            // Setup hot module replacement
            // @ts-expect-error [TS migration] (previously $FlowFixMe) type is not correctly typed
            module.hot.accept('./routes', () =>
                setImmediate(() => {
                    root.unmount();
                    render();
                }),
            );
        }
    }

    // ========================================================
    // Go!
    // for the SW to be activated, use localhost instead of 0.0.0.0
    // ========================================================
    if (!IS_TEST) {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker

                    .register('/service-worker.js')
                    .then((registration) => {
                        // eslint-disable-next-line no-console
                        console.log('SW registered: ', registration);
                    })
                    .catch((registrationError) => {
                        console.error(
                            'SW registration failed: ',
                            registrationError,
                        );
                    });
            });
        }
        render();
    }
}
